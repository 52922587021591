.change-opacity{
    opacity: 0.7;
    transition: all 0.3s linear;
}

.change-opacity:hover{
    opacity: 1;
}

.hero_para_div{
    /* background-color: red; */
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    overflow: visible;
    min-height: 100px;
}
.hero_para_div_btn{
    /* background-color: red; */
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    overflow: visible;
    /* min-height: 100px; */
}
.hero_para_text{
    color: #fff !important;
}