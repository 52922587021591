/* .cool-link::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: blueviolet;
    transition: width .3s;
} */
.cool-link{
    color: #808C9D;
    font-weight: 400;
    line-height: 20px;
}
.cool-link:hover{
    color: black;
    text-decoration: none;
}
/* .cool-link:hover::after { */
    /* width: 80%; */
    /* //transition: width .3s; */
/* } */
.cus-ul{
    list-style-image: none;
}